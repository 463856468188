var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"mb-8",attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Filtros")]),_c('v-expansion-panel-content',[_c('v-form',{ref:"filterForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitFilter()}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('input-date',{attrs:{"label":_vm.$t('creationStartTime'),"rules":[_vm.requiredRule, _vm.dateLength],"clearable":"","dense":""},model:{value:(_vm.filters.creationStartTime),callback:function ($$v) {_vm.$set(_vm.filters, "creationStartTime", $$v)},expression:"filters.creationStartTime"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('input-date',{attrs:{"label":_vm.$t('creationFinishTime'),"rules":[
                _vm.requiredRule,
                _vm.dateLength,
                _vm.dataIntervalMustBeLessThanDays(
                  _vm.filters.creationStartTime,
                  _vm.filters.creationFinishTime,
                  _vm.searchDateLimitDays
                ) ],"clearable":"","dense":""},model:{value:(_vm.filters.creationFinishTime),callback:function ($$v) {_vm.$set(_vm.filters, "creationFinishTime", $$v)},expression:"filters.creationFinishTime"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.companyCodeList,"label":_vm.filtroEmpresa,"rules":[_vm.requiredRule],"outlined":"","dense":""},model:{value:(_vm.filters.companyCode),callback:function ($$v) {_vm.$set(_vm.filters, "companyCode", $$v)},expression:"filters.companyCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"label":_vm.$t('nfNumber'),"rules":[_vm.valueMustBeNumberHifen, _vm.length(_vm.filters.nfNumber, 9)],"outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.filters.nfNumber = ''}},model:{value:(_vm.filters.nfNumber),callback:function ($$v) {_vm.$set(_vm.filters, "nfNumber", $$v)},expression:"filters.nfNumber"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-btn',{attrs:{"depressed":"","color":"#D8D8D8","block":_vm.$vuetify.breakpoint.xsOnly,"type":"submit"}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)],1)],1)],1),_c('TLoading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }