<template>
  <v-expansion-panels class="mb-8" v-model="panels" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header>Filtros</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="filterForm" @submit.prevent="submitFilter()">
          <v-row dense>
            <v-col cols="12" md="2">
              <input-date
                v-model="filters.creationStartTime"
                :label="$t('creationStartTime')"
                :rules="[requiredRule, dateLength]"
                clearable
                dense
              />
            </v-col>
            <v-col cols="12" md="2">
              <input-date
                v-model="filters.creationFinishTime"
                :label="$t('creationFinishTime')"
                :rules="[
                  requiredRule,
                  dateLength,
                  dataIntervalMustBeLessThanDays(
                    filters.creationStartTime,
                    filters.creationFinishTime,
                    searchDateLimitDays
                  ),
                ]"
                clearable
                dense
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                v-model="filters.companyCode"
                :items="companyCodeList"
                :label="filtroEmpresa"
                :rules="[requiredRule]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filters.nfNumber"
                :label="$t('nfNumber')"
                :rules="[valueMustBeNumberHifen, length(filters.nfNumber, 9)]"
                outlined
                dense
                clearable
                @click:clear="filters.nfNumber = ''"
                v-mask="'#########'"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="5">
                  <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <TLoading v-model="loading" />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import mixinFilters from '@/mixins/mixinFilter'
import mixinRules from '@/mixins/mixinRules'
import InputDate from '@/components/InputDate.vue'

export default {
  name: 'InvoicesFilterComponent',
  mixins: [mixinFilters, mixinRules],
  components: {
    InputDate,
  },
  data() {
    return {
      path: '',
      panels: [0],
      searchDateLimitDays: 30,
      filters: {
        startTime: '',
        finishTime: '',
        companyCode: '',

        creationStartTime: '',
        creationFinishTime: '',
        cteNumber: '',
        nfNumber: '',
        emmiterCode: '',
      },
    }
  },
  methods: {
    prepareFilter() {
      const filter = {}
      this.verifyNullableFilter(filter, 'startTime', this.filters.startTime, 'T00:00:00-03:00')
      this.verifyNullableFilter(filter, 'finishTime', this.filters.finishTime, 'T23:59:59-03:00')
      this.verifyNullableFilter(filter, 'companyCode', this.filters.companyCode)

      this.verifyNullableFilter(filter, 'creationStartTime', this.filters.creationStartTime, `T00:00:00-03:00`)
      this.verifyNullableFilter(filter, 'creationFinishTime', this.filters.creationFinishTime, `T23:59:59-03:00`)
      this.verifyNullableFilter(filter, 'cteNumber', this.filters.cteNumber)
      this.verifyNullableFilter(filter, 'nfNumber', this.filters.nfNumber)
      this.verifyNullableFilter(filter, 'emmiterCode', this.filters.emmiterCode)

      return filter
    },
    findKey(object, key, value) {
      Object.keys(object).some(function (k) {
        if (k === key) {
          if (typeof object[k] == 'boolean') {
            object[k] = JSON.parse(value)
          } else {
            object[k] = value
          }
          return true
        }
      })
    },
  },
}
</script>
